import Cookies from "js-cookie";
import { urls } from "../../url";
import { toast } from 'react-toastify';
import { checkAccessToken } from "../auth/authApi";

const TOASTER_TIMEOUT = 1000;

export async function CreateUser(userData) {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }
  const payload = { "data": userData };
  return new Promise(async (resolve) => {
    const response = await fetch(urls.registeruser, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    });

    if (response.status === 401) {
      // Handle 400 or 401 error by removing cookies and reloading the app
      Cookies.remove('accessToken'); // Remove the accessToken cookie
      toast.error('Session expired, Please Login Again!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
      setTimeout(() => {
        window.location.reload(); // Reload the app
      }, 3000);
    } else if (response.status == 400) {

      const responseError = await response.json()

      toast.error(responseError.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
    } else {
      const data = await response.json();
      resolve({ data });
      // {"data": {"message": "Registration Successful!"}}
      toast.success('Registration Successful!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }
  });
}

export async function fetchAllUsers() {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  return new Promise(async (resolve) => {
    const response = await fetch(urls.getUsers, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    // if (response.status === 400 || response.status === 401) {
    //   // Handle 400 or 401 error by removing cookies and reloading the app
    //   Cookies.remove('accessToken'); // Remove the accessToken cookie
    //   toast.error('Session expired. Logging out...', {
    //     position: 'top-right',
    //     autoClose: 3000,
    //   });
    //   setTimeout(() => {
    //     window.location.reload(); // Reload the app
    //   }, 3000);
    // } else {
      const data = await response.json();
      resolve({ data });
    // }
  });
}

export async function fetchAllFromUsers() {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  return new Promise(async (resolve) => {
    const response = await fetch(urls.getFromUsers, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    // if (response.status === 400 || response.status === 401) {
    //   // Handle 400 or 401 error by removing cookies and reloading the app
    //   Cookies.remove('accessToken'); // Remove the accessToken cookie
    //   toast.error('Session expired. Logging out...', {
    //     position: 'top-right',
    //     autoClose: 3000,
    //   });
    //   setTimeout(() => {
    //     window.location.reload(); // Reload the app
    //   }, 3000);
    // } else {
      const data = await response.json();
      resolve({ data });
    // }
  });
}

export async function removeFromUser(id) {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  return new Promise(async (resolve) => {
    const payload = { data: { id: id } };
    const response = await fetch(urls.deleteFromUser, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload),
    });
    if (response.status === 401) {
      // Handle 400 or 401 error by removing cookies and reloading the app
      Cookies.remove('accessToken'); // Remove the accessToken cookie
      toast.error('Session expired, Please Login Again!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
      setTimeout(() => {
        window.location.reload(); // Reload the app
      }, 3000);
    } else if (response.status == 400) {

      const responseError = await response.json()

      toast.error(responseError.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      }) } else {
      const data = await response.json();
      //{"data": {"message": "User 5 Deleted Successfully!"}}
      toast.success('User Deletion Successful!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
      resolve(data);
    }
  });
}

export async function removeUser(id) {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  return new Promise(async (resolve) => {
    const payload = { data: { id: id } };
    const response = await fetch(urls.deleteUser, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload),
    });
    if (response.status === 401) {
      // Handle 400 or 401 error by removing cookies and reloading the app
      Cookies.remove('accessToken'); // Remove the accessToken cookie
      toast.error('Session expired, Please Login Again!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
      setTimeout(() => {
        window.location.reload(); // Reload the app
      }, 3000);
    } else if (response.status == 400) {

      const responseError = await response.json()

      toast.error(responseError.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      }) } else {
      const data = await response.json();
      //{"data": {"message": "User 5 Deleted Successfully!"}}
      toast.success('User Deletion Successful!', {
        position: 'top-right',
        autoClose: 3000
      });
      resolve(data);
    }
  });
}

export async function CreateFromUser(fromEmailData) {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  const payload = { "data": {"users": [fromEmailData]} };

  return new Promise(async (resolve) => {
    const response = await fetch(urls.registerFromUser, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    });

    if (response.status === 401) {
      // Handle 400 or 401 error by removing cookies and reloading the app
      Cookies.remove('accessToken'); // Remove the accessToken cookie
      toast.error('Session expired, Please Login Again!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
      setTimeout(() => {
        window.location.reload(); // Reload the app
      }, 3000);
    } else if (response.status == 400) {

      const responseError = await response.json()

      toast.error(responseError.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
    } else {
      const data = await response.json();
      resolve({ data });
      // {"data": {"message": "Registration Successful!"}}
      toast.success('Registration Successful!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }
  });
}