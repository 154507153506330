import React, { useState } from 'react';
 import Navbar from '../../header/Navbar';

export default function AdminHome() {
 
  return (
    <div>
    
         <Navbar/>    
        {/* <Outlet/> */}

    </div>
  );
}
