import React from 'react';
import {
    Button,
    TextField,
    Typography,
    Container,
    Paper,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createFromUserAsync } from '../adminSlice';
import './admin.css'

const AdminFromUserRegister = () => {
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    return (
        <div>
            <Container maxWidth="xs" sx={{ marginTop: "4%", height: "36rem" }}>
                <Paper elevation={3} style={{ padding: 20 }}>
                    <Typography variant="h6" gutterBottom>
                        Register From Users
                    </Typography>

                    <form
                        onSubmit={handleSubmit((data) => {
                            dispatch(createFromUserAsync({ email: data.email, clientId: data.clientId, tenantId: data.tenantId, secret: data.secret, scopes: data.scopes }));
                            console.log(data);
                        })}
                    >
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi,
                                    message: "Email is not valid",
                                },
                            })}
                            error={!!errors.email}
                        />
                        <p className={`text-danger ${errors.email ? '' : 'd-none'}`}>
                            {errors.email && errors.email.message}
                        </p>

                        <TextField
                            id="clientId"
                            label="Client Id"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            {...register("clientId", { required: "Client ID is required" })}
                            error={!!errors.clientId}
                        />
                        <p className={`text-danger ${errors.clientId ? '' : 'd-none'}`}>
                            {errors.clientId && errors.clientId.message}
                        </p>

                        <TextField
                            id="tenantId"
                            label="Tenant Id"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            {...register("tenantId", { required: "Tenant ID is required" })}
                            error={!!errors.clientId}
                        />
                        <p className={`text-danger ${errors.tenantId ? '' : 'd-none'}`}>
                            {errors.tenantId && errors.tenantId.message}
                        </p>

                        <TextField
                            id="secret"
                            label="Secret"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            {...register("secret", { required: "Secret is required" })}
                            error={!!errors.clientId}
                        />
                        <p className={`text-danger ${errors.secret ? '' : 'd-none'}`}>
                            {errors.secret && errors.secret.message}
                        </p>

                        <TextField
                            id="scopes"
                            label="Scopes"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            {...register("scopes", { required: "Scopes is required" })}
                            error={!!errors.clientId}
                        />
                        <p className={`text-danger ${errors.scopes ? '' : 'd-none'}`}>
                            {errors.scopes && errors.scopes.message}
                        </p>

                        <Button variant="contained" color="primary" fullWidth type="submit">
                            Register
                        </Button>
                    </form>
                </Paper>
            </Container>
        </div>
    );
};

export default AdminFromUserRegister;
