import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import './sidebar.css'
import './admin.css'

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: open ? 0 : `-${drawerWidth}px`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: open ? theme.transitions.duration.leavingScreen : 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const AdminSideBar = ({ item = [] }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(true);
  const [activeItem, setActiveItem] = useState('');

  // const handleDrawerToggle = () => {
  //   setOpen(!open);
  // };

  const sidebarSize = open ? "15%" : "4%";
  const MainbarSize = open ? "85%" : "96%";

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: sidebarSize,
          flexShrink: 0,
          backgroundColor: '#f2f2f2',
          marginTop: "4.5%",
          '& .MuiDrawer-paper': {
            width: sidebarSize,
            backgroundColor: '#f2f2f2',
            marginTop: "4.5%",
          },
        }}
        variant="persistent"
        anchor="left"
        open={true}
      >
        <DrawerHeader>
          {/* <IconButton onClick={handleDrawerToggle}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton> */}
        </DrawerHeader>
        <Divider />
        <List>
          {item.map((each) => (
            <ListItem
              key={each.text}
              disablePadding
              className={activeItem === each.text ? 'active' : ''}
            >
              <ListItemButton
                component={Link}
                to={each.navUrl}
                onClick={() => setActiveItem(each.text)} // Set the active item when clicked
              >
                <ListItemIcon>{each.icon}</ListItemIcon>
                {open ? <ListItemText primary={each.text} /> : null}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box sx={{width:MainbarSize}} className='px-3'>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminSideBar;
