import React, { useEffect, useState } from 'react';
import './EmailCompo.css';
import { fetchedUserEmailAsync, getHeadersAsync, selectEmailData, selectHeaders } from '../userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getBccAsync, getCcAsync, getSubjectLineAsync } from '../userSlice';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

const EmailCompo = ({ onSelectEmailAndHeaders, selectedEmailIds, selectedHeadersData ,onEMailSelectedSpinner}) => {
    const [userId, setUserId] = useState(0);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [activeEmail, setActiveEmail] = useState(null);
    const [selectedHeaders, setSelectedHeaders] = useState(null);
    const headerData = useSelector(selectHeaders)
    const emailData = useSelector(selectEmailData);
    const fields = emailData.fields || [];
    const rowsCount = emailData.metadata?.rowsCount || 0;
    const dispatch = useDispatch();

    const handleEmailClick = (email, rowID) => {
        setActiveEmail(email);
        setUserId(rowID);
        onEMailSelectedSpinner(rowID)
        dispatch(getCcAsync(rowID))
        dispatch(getBccAsync(rowID))
        dispatch(getSubjectLineAsync(rowID))
        setSelectedHeaders(headerData.data?.fields);

    };

    useEffect(() => {
        dispatch(fetchedUserEmailAsync())
    }, [dispatch]);

    useEffect(() => {
        onSelectEmailAndHeaders(activeEmail, selectedHeaders);
    }, [activeEmail, selectedHeaders]);

    useEffect(() => {
        dispatch(getHeadersAsync(0));
        // Set Default Row Zero Selected for Email -> cc, bcc, subjectline.
        dispatch(getCcAsync(userId))
        dispatch(getBccAsync(userId))
        dispatch(getSubjectLineAsync(userId))

    }, [dispatch, 0])

    const handleRowCountChange = (event) => {
        const selectedRow = event.target.value;
        setSelectedRowCount(selectedRow);
        fetchDataForSelectedRow(selectedRow);
    };

    const fetchDataForSelectedRow = (selectedRow) => {

        dispatch(getHeadersAsync(selectedRow));
    };

    const rowOptions = Array.from({ length: rowsCount + 1 }, (_, index) => index);

    return (
        <div className='mainEmail'>
            <div className="column left-column">
                <h6 className="header-with-underline">Emails Found (Column A)</h6>
                <div className="email-list">
                    <div className="scrollable-list">
                        {fields.map((emailItem, index) => (
                            <Tooltip
                                title="Select Email to Toggle CC, BCC & Subject Line"
                                placement="top"
                                arrow
                                key={index}
                            >
                                <div
                                    key={index}
                                    className={`email-item ${index % 2 === 0 ? 'even' : 'odd'} ${userId === emailItem.rowID ? 'active' : ''}`}
                                    onClick={() => handleEmailClick(emailItem.email, emailItem.rowID)}
                                >
                                    {emailItem.email}
                                </div>
                            </Tooltip>
                        ))}

                    </div>
                </div>
            </div>

            <div className="column right-column">
                <h6 className="header-with-underline">File Headers</h6>
                <label style={{ fontSize: "small", fontWeight: "bold" }}>Select Row:</label>
                <Select
                    value={selectedRowCount}
                    onChange={handleRowCountChange}
                    displayEmpty
                    style={{ width: "55%", fontSize: "small", height: "4.5%", marginLeft: "5px" }}
                >
                    {rowOptions.map((row, index) => (
                        <MenuItem key={index} value={row}>
                            {row + 1}
                        </MenuItem>
                    ))}
                </Select>
                <div className="header-list">
                    {headerData?.data?.fields
                        .filter(item => item.field !== 'rowId') // Exclude rowId
                        .map((item, index) => (
                            <div
                                key={index}
                                className={`header-item ${index % 2 === 1 ? 'odd' : ''}`}
                            >
                                <span className="header-content" style={{ fontWeight: "500" }}>
                                    [{item.columnName}]
                                </span>
                                <br />
                                <span className="header-content">
                                    ({item.field})
                                </span>
                                <br />
                                <span className="header-content" style={{ fontWeight: "500" }}>
                                    {item.value}
                                </span>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default EmailCompo;