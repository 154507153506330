 import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uploadUsers, fetchUserEmail, getCC, getBcc, getSubjectLine, getHeaders, getFromEmails, uploadFromEmails ,uploadEmailData, uploadAttachment, emailBodyTemplate, getPreviewEmail, EmailBlast, getEmailBlastStatus} from './userApi';

const initialState = {
  tableData: [],
  emailData: [],
  cc:[],
  bcc:[],
  subjectLine:[],
  headers:[],
  fromEmailData:[],
  emailBody:[],
  email:[],
  emailBlastStatusData: [],
}

export const fetchedUserEmailAsync = createAsyncThunk(
  'user/fetchAllUsers',
  async () => {
    const response = await fetchUserEmail();
    return response.data;
  }
);

export const emailBodyTemplateAsync = createAsyncThunk(
  'user/emailBodyTemplate',
  async () => {
    const response = await emailBodyTemplate();
    return response.data;
  }
);


export const getCcAsync = createAsyncThunk(
  'user/getCC',
  async (rowID) => {
     const response = await getCC(rowID);
    return response.data;
  }
);
export const getBccAsync = createAsyncThunk(
  'user/getBcc',
  async (rowID) => {
    const response = await getBcc(rowID);
    return response.data;
  }
);
export const getSubjectLineAsync = createAsyncThunk(
  'user/getSubjectLine',
  async (rowID) => {
    const response = await getSubjectLine(rowID);
    return response.data;
  }
);

export const getPreviewEmailAsync = createAsyncThunk(
  'user/getPreviewEmail',
  async (rowID) => {
    const response = await getPreviewEmail(rowID);
    return response.data;
  }
);

export const getHeadersAsync = createAsyncThunk(
  'user/getHeaders',
  async (rowID) => {
    const response = await getHeaders(rowID);
    return response.data;
  }
);

export const getFromEmailsAsync = createAsyncThunk(
  'user/getFromEmails',
  async () => {
    const response = await getFromEmails();
    return response.data;
  }
);

export const uploadFromEmailsAsync = createAsyncThunk(
  'user/uploadFromEmails',
  async (selectedEmailId) => {
    const response = await uploadFromEmails(selectedEmailId);
    return response.data;
  }
);

export const uploadUsersAsync = createAsyncThunk(
  'user/uploadUsers',
  async (file) => {
     const response =   uploadUsers(file);
     console.log(response)
      return response.data;
  }
);

export const uploadEmailDataAsync = createAsyncThunk(
  'user/uploadEmailData',
  async (emailData) => {
     const response =   uploadEmailData(emailData);
     return response.data;
  }
);

export const uploadAttchmentAsync = createAsyncThunk(
  'user/uploadAttachment',
  async (formData) => {
     const response =   uploadAttachment(formData);
     return response.data;
  }
);

export const EmailBlastAsync = createAsyncThunk(
  'admin/EmailBlast',
  async () => {
    const response = await EmailBlast();
     return response.data;
  }
);

export const fetchEmailBlastStatusAsync = createAsyncThunk(
  'user/fetchEmailBlastStatus',
  async () => {
    const response = await getEmailBlastStatus();
    return response.data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setUploadedUserData: (state, action) => {
      state.uploadedUserData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(uploadUsersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadUsersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        // Update state properties here as needed
      })
      .addCase(fetchedUserEmailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchedUserEmailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.emailData = action.payload;

      })
      .addCase(getHeadersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getHeadersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.headers = action.payload;
      })
      .addCase(getCcAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCcAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cc = action.payload;
      })
      .addCase(getBccAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBccAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.bcc = action.payload;
      })
      .addCase(getSubjectLineAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSubjectLineAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.subjectLine = action.payload;
      })
      .addCase(getFromEmailsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFromEmailsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.fromEmailData = action.payload;
      })
       .addCase(uploadEmailDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadEmailDataAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(uploadAttchmentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadAttchmentAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(emailBodyTemplateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(emailBodyTemplateAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.emailBody = action.payload
      })
      .addCase(getPreviewEmailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPreviewEmailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.email = action.payload
      })
      .addCase(EmailBlastAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(EmailBlastAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(fetchEmailBlastStatusAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmailBlastStatusAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.emailBlastData = action.payload
      })
  },
})

export const { setTableData, setUploadedUserData } = userSlice.actions;

export const selectTableData = (state) => state.user.tableData;
export const selectEmailData = (state) => state.user.emailData;
export const selectCC = (state) => state.user.cc;
export const selectBcC = (state) => state.user.bcc;
export const selectSubjectLine = (state) => state.user.subjectLine;
export const selectHeaders = (state) => state.user.headers;
export const selectFromEmailData = (state) => state.user.fromEmailData;
export const selectEmailBodyData = (state) => state.user.emailBody;
export const selectPreviewEmail = (state) => state.user.email;
export const emailBlastStatusData = (state) => state.user.emailBlastData;

export default userSlice.reducer;
