import React, { useEffect, useState } from 'react';
import { EmailBlastAsync, fetchedUserEmailAsync, getPreviewEmailAsync, selectEmailData, selectPreviewEmail } from '../userSlice';
import { useDispatch, useSelector } from 'react-redux';
import './previewEmail.css';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import InfoButton from './infoButton';
import Typography from '@mui/material/Typography';

const PreviewEmail = () => {
    const [activeEmail, setActiveEmail] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [userId, setUserId] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false);
    const [loadContent, setLoadContent] = useState(true);


    const emailData = useSelector(selectEmailData);
    const fields = emailData.fields || [];

    let previewEmailData = useSelector(selectPreviewEmail);

    let previewEMailContent = previewEmailData?.data?.content;

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const location = useLocation();
    const { selectedAttachments, selectedEmailId, emailBody, selectedEmail, selectedHeaders } = location.state || {};

    console.log('Received data:', selectedAttachments, selectedEmailId);

    const InfoButtonContent = (
        <Typography>
          <ul>
            <li>Click on Emails to generate preview.</li>
          </ul>
        </Typography>
      )

    useEffect(() => {
        dispatch(getPreviewEmailAsync(userId));
        dispatch(fetchedUserEmailAsync());
    }, [dispatch]);

    const handleEmailClick = (email, rowID) => {
        setActiveEmail(email);
        setUserId(rowID);
        dispatch(getPreviewEmailAsync(rowID));
    };

    const handleConfirmClick = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogConfirm = async () => {
        try {
            await dispatch(EmailBlastAsync());
            setOpenDialog(false);
            navigate('/user');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('Unauthorized access. Please log in.', {
                    position: 'top-right',
                    autoClose: 2000,
                });
            } else {
                console.error('An error occurred:', error.message);
                toast.error('An error occurred. Please try again.', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }

        }
    };

    useEffect(() => {
        const showSpinnerForOneSecond = () => {
            setShowSpinner(true);
            setTimeout(() => {
                setShowSpinner(false);
            }, 2000);
        };
        if (previewEMailContent) {
            showSpinnerForOneSecond();
            setLoadContent(false);
        }
    }, [previewEMailContent]);


    const handleBackClick = () => {

        if (selectedAttachments || selectedEmailId || emailBody) {
            navigate('/user/emailcustomize', {
                state: {
                    selectedAttachments,
                    selectedEmailId,
                    emailBody,
                    selectedEmail,
                    selectedHeaders
                },
            });
        } else {

            console.error("Error: Data is not defined");
        }
    }

    return (
        <>
        <InfoButton toolTipContent={InfoButtonContent} />
            <div className='mainpreviewEmail'>
                <div className="column left-column-preview">
                    <h6 className="header-with-underline">Emails Found (Column A)</h6>
                    <div className="email-list">
                        <div className="scrollable-list-preview">
                            {fields.map((emailItem, index) => (
                                <Tooltip
                                    title="Select Email to Generate Preview"
                                    placement="top"
                                    arrow
                                    key={index}
                                >
                                    <div
                                        key={index}
                                        className={`email-item ${index % 2 === 0 ? 'even' : 'odd'} ${userId === emailItem.rowID ? 'active' : ''}`}
                                        onClick={() => handleEmailClick(emailItem.email, emailItem.rowID)}
                                    >
                                        {emailItem.email}
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='preview-email'>
                    {showSpinner && (
                        <div className="loading-overlay-preview">
                            <CircularProgress size={24} />
                        </div>
                    )}
                    <div dangerouslySetInnerHTML={{ __html: previewEmailData?.data?.content || "<h6>Please Click on Emails to Generate Preview</h6>" }} ></div>
                </div>
            </div>
            <div className="button-container button-preview">
                {/* <Link to='/user/emailcustomize' > */}
                <Tooltip title="Back to Email Customization" placement="top-end" arrow>
                    <Button variant="outlined" onClick={handleBackClick} color="primary">
                        Back
                    </Button>
                </Tooltip>
                {/* </Link> */}
                <Tooltip title="Trigger Email Blast" placement="top-end" arrow>
                <Button variant="contained" color="primary" onClick={handleConfirmClick} endIcon={<SendIcon />}>
                    Confirm
                </Button>
                </Tooltip>
            </div>
            {/* Modal Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Email Blast"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to trigger the Email Blast?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogConfirm} color="success" endIcon={<SendIcon />}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PreviewEmail;