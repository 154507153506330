import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import * as XLSX from 'xlsx';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { uploadUsersAsync } from '../userSlice';
import './user.css';
import { toast } from 'react-toastify';
import Navbar from '../../header/Navbar';
import { Link } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { saveAs } from 'file-saver';
import Typography from '@mui/material/Typography';
import InfoButton from './infoButton';
import { uploadUsers } from '../userApi';

const UserHome = () => {
  const [uploadedData, setUploadedData] = useState([]);
  const [error, setError] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  const dispatch = useDispatch();
  const [containerHeight, setContainerHeight] = useState('20rem');
  const [dropZoneText, setDropZoneText] = useState("Drag 'n' Drop, or Click to Select Excel File!")
  const [uploadedFileName, setUploadedFileName] = useState("")
  const [showSpinner, setShowSpinner] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const handleInfoClick = () => {
    setShowInfo(!showInfo);
  }
  const toolTipContent = (
    <Typography>
      <ul>
        <li>All "Date" Columns must be formatted as "Text" in the Excel before uploading.</li>
        <li>CC & BCC Emails in the Excel are to be separated using either a comma or semi colon.</li>
        <li>The maximum number of columns allowed in the Excel sheet is 75.</li>
        <li>Duplicates emails in 'To' section will be highlighted in Yellow, please ensure these duplicates are removed before proceeding.</li>
      </ul>
    </Typography>
  )

  const activateSpinner = (spinnerDelay) => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, spinnerDelay);
  }

  const handleExcelDownload = async () => {
    const confirmed = window.confirm('Are you sure you want to download the Email Blaster Excel Template?');

    if (confirmed) {
      try {
        const response = await fetch('/assets/Email Blaster Excel Template.xlsx');
        const blob = await response.blob();
        saveAs(blob, 'Email Blaster Excel Template.xlsx');
      } catch (error) {
        console.error('Error downloading Excel file:', error);
      }

    }
  }


  const handleDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) {
      setError('No file uploaded!');
      return;
    }

    const fileType = file.type;

    if (fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      // Display a toast message for an invalid file type
      toast.error('Only Excel File (.xlsx) Allowed!', {
        position: 'top-right',
        autoClose: 3000
      });
      return;
    }

    // Continue processing for Excel files
    activateSpinner(file.size / 10);
    setContainerHeight('5rem');
    setDropZoneText("Upload a New Excel File");

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(sheet, { defval: "", blankrows: true });

        const validData = data.map((row) => {
          const isEmailValid = validateEmail(row.Email);
          return {
            ...row,
            isEmailValid,
          };
        });

        try {
           const response = await uploadUsers(file);

           if (response) {
             const headers = Object.keys(data[0]);
            setTableHeaders(headers);
            setUploadedData(validData);
            setUploadedFileName(file.name);
            setError('');
          } else {
            setTableHeaders([]);
            setUploadedData([]);
            setUploadedFileName("");
            setError('');
          }
        } catch (error) {
          //  toast.error('Error uploading the file!', {
          //   position: 'top-right',
          //   autoClose: 3000
          // });
          setTableHeaders([]);
          setUploadedData([]);
          setUploadedFileName("");
          setError('');
        }
      } catch (err) {
        toast.error('Error processing Excel file!', {
          position: 'top-right',
          autoClose: 3000
        });
      }
    };

    reader.readAsArrayBuffer(file);
  };



  const validateEmail = (email) => {
    const emailRegex = /^[\w\.-]+@[\w\.-]+\.\w+$/;
    const emailList = email.split(/[;,]+/);

    for (const email of emailList) {
      if (!emailRegex.test(email.trim())) {
          return false;
      }
    }


    return true;
  };

  const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    display: 'flex',
    height: containerHeight,
    transition: 'height 1s ease',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    padding: '20px',
    cursor: 'pointer',
    marginTop: '1%',
    marginLeft: '5%',
  };

  const tableContainerStyle = {
    width: '90%',
    marginLeft: '5%',
    marginTop: '1%',
    overflowY: "auto",
    height: "17rem",
  };
  const tableSpinnerContainerStyle = {
    width: '90%',
    marginLeft: '5%',
    marginTop: '10%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  };

  const tableCellStyle = {
    border: '1px solid #ddd'
  };
  const tableHeaderCellStyle = {
    border: '1px solid #ddd',
    fontWeight: 'bold'
  };

  const rowStyle = {
    backgroundColor: '#FFFFFF',
  };

  const invalidEmailRowStyle = {
    backgroundColor: '#FFCCCB',
  };
  const highlightedRowStyle = {
    backgroundColor: '#FFFBC8',
  };

  const downloadExcelButtonStyle = {
    marginTop: "6%",
    marginLeft: "5%",
    color: "grey",
    border: "0.5px solid grey"
  }

  return (
    <div className="containerStyle">
      <Navbar />
      <Tooltip title="Click to Download Email Blaster Excel Template" placement="top-start" arrow>
        <Button variant="outlined" size="small" onClick={handleExcelDownload} style={downloadExcelButtonStyle}>
          <FileDownloadOutlinedIcon />
          <Typography variant="body2">Excel Template</Typography>
        </Button>
      </Tooltip>
      <InfoButton onClick={handleInfoClick} toolTipContent={toolTipContent} />
      <Dropzone
        onDrop={handleDrop}
        accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className="dropzoneStyles"
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>
              <FileUploadOutlinedIcon />
              {dropZoneText}
            </p>
          </div>
        )}
      </Dropzone>
      {error && <p>{error}</p>}
      <div className="tableContainer">
        {uploadedData.length > 0 && (
          <div>
            <p style={{ marginLeft: '5%', marginTop: '1%', color: 'grey' }}>
              {uploadedFileName}
            </p>

            {showSpinner ? (
              <div style={tableSpinnerContainerStyle}>
                <CircularProgress size={24} />
              </div>
            ) : (
              <div>
                <TableContainer component={Paper} style={tableContainerStyle} className="tableContainer">
                  <Table>
                    <TableHead>
                      <TableRow>
                        {tableHeaders.map((header, index) => (
                          <TableCell key={index} style={tableHeaderCellStyle}>
                            <TableSortLabel active={false} direction={'asc'}>
                              {header}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uploadedData.map((row, index) => (
                        <TableRow key={index} style={
                          !validateEmail(row.Email) //invalid email check
                            ? invalidEmailRowStyle  // invalid email color
                            : Object.values(row).some((cellValue) => cellValue === '')  // check blank value
                              ? highlightedRowStyle // blank value color
                              : rowStyle   // default color 
                        }>
                          {tableHeaders.map((header, index) => (
                            <TableCell key={index} style={tableCellStyle}>
                              {row[header]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginRight: '5%' }}>
                  <Link to="/user/emailcustomize">
                    <Tooltip title="Move to Email Customization" placement="top-start" arrow>
                      <Button variant="contained" color="primary">
                        Next
                      </Button>
                    </Tooltip>
                  </Link>

                </div>
              </div>
            )
            }
          </div>
        )}
      </div>
    </div >
  );
};

export default UserHome;