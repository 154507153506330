import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './emailCustomize.css';
import Navbar from '../../header/Navbar';
import EmailCompo from './emailCompo';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import { Button } from "@mui/material";
import EmailChips from './emailChips';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmailBodyData, uploadAttchmentAsync, uploadEmailDataAsync, uploadFromEmailsAsync, emailBodyTemplateAsync } from '../userSlice';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import InfoButton from './infoButton';
import Typography from '@mui/material/Typography';


const EmailCustomize = () => {
    const [loading, setLoading] = useState(true);
    const [selectedAttachments, setSelectedAttachments] = useState([]);
    const [selectedEmailId, setSelectedEmailId] = useState('');
    const [selectedSpinner , setSelectedSpinner]=useState('')
    const [contentError, setContentError] = useState('');
    const [fromEmailError, setFromEmailError] = useState(''); // State for "From Emails" error
    const [emailBody, setEmailBody] = useState(''); // State to store email body
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [selectedHeaders, setSelectedHeaders] = useState(null);
    const editorRef = useRef();
    const dispatch = useDispatch();
    const emailBodyTemplateData = useSelector(selectEmailBodyData);
    const navigate = useNavigate()

    const location = useLocation();

    const InfoButtonContent = (
        <Typography>
          <ul>
            <li>Attachments of a maximum size of 20 MB are allowed.</li>
            <li>“From Email” must be selected for generating Email Preview.</li>
            <li>Images of a maximum size of 15 MB are allowed.</li>
            <li>Attachment file formats supported: pdf, png, jpg, jpeg, xlsx, csv, doc, and docx.</li>
          </ul>
        </Typography>
      )

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        if (location.state) {
            const { emailBody, selectedAttachments, selectedEmailId, selectedEmail, selectedHeaders } = location.state;
            console.log(selectedAttachments)

            const filelist = {
                FileList: selectedAttachments
            }
            const arrayFromInput = filelist.FileList;
            const outputObject = { 0: arrayFromInput[0], length: arrayFromInput.length };
            // Assuming setSelectedAttachments is a state-setting function
            console.log(outputObject, "outputObject")
            console.log(selectedAttachments)
            setSelectedAttachments(selectedAttachments);
            setSelectedEmailId(selectedEmailId);
            setSelectedEmail(selectedEmail)
            setSelectedHeaders(selectedHeaders)
            if (emailBody) {
                setEmailBody(emailBody);
            } else {
                dispatch(emailBodyTemplateAsync());
            }
        }
        dispatch(emailBodyTemplateAsync());

        return () => clearTimeout(timer);
    }, [])



    const handleAttachmentSelected = (newAttachments) => {
        setSelectedAttachments(newAttachments);
    };

    const handleEmailIdSelected = (id) => {
        setSelectedEmailId(id);
    };

   const handleSelectedSpinner=(id)=>{
    setSelectedSpinner(id)
   } 

    const handleSelectEmailAndHeaders = (email, headers) => {
        setSelectedEmail(email);
        setSelectedHeaders(headers);
    };

    const handleConfirmClick = async () => {
        const emailData = editorRef.current.getData();
        const formData = new FormData();
        if (selectedAttachments && Array.isArray(selectedAttachments)) {
            selectedAttachments.forEach((attachment, index) => {
                formData.append(`files`, attachment);
            });
        } else {
            // Handle the case when selectedAttachments is null or not an array
            console.error("Selected attachments is null or not an array");
        }


        if (emailData.trim() === '') {
            setContentError('Email content is required');
            toast.error('Email Body is Required to Confirm!', { autoClose: 2000 });
            return;
        } else {
            setContentError('');
            toast.dismiss();
        }

        if (!selectedEmailId) {
            setFromEmailError('From Emails is required');
            toast.error('From Email is Required to Confirm!', { autoClose: 2000 });
            return;
        } else {
            setFromEmailError('');
            toast.dismiss();
        }

        setSelectedAttachments(selectedAttachments);
        setSelectedEmailId(selectedEmailId);
        setEmailBody(emailData);
        setSelectedEmail(selectedEmail);
        setSelectedHeaders(selectedHeaders);

        try {
            await dispatch(uploadAttchmentAsync(formData));
            await dispatch(uploadEmailDataAsync(emailData));
            await dispatch(uploadFromEmailsAsync(selectedEmailId));

            const dataToPass = {
                selectedAttachments,
                selectedEmailId,
                emailBody: emailData,
                selectedEmail,
                selectedHeaders
            };

            console.log('Data to pass:', dataToPass);
            navigate('/user/previewEmail', { state: dataToPass });
        } catch (error) {
            console.error("Error uploading attachments and email data:", error);
        }
    };

    return (
        <>
            <div className="email-customize-container">
                <div className="component-content">
                    <Navbar />
                    <InfoButton toolTipContent={InfoButtonContent} />
                    <div className='emailcustomize'>
                        <div className='one'>
                            <EmailCompo onSelectEmailAndHeaders={handleSelectEmailAndHeaders} selectedEmailIds={selectedEmail} selectedHeadersData={selectedHeaders} onEMailSelectedSpinner={handleSelectedSpinner} />
                        </div>
                        <div className='maindiv'>
                            <div className='two'>
                                <EmailChips selectedAttachmentsId={selectedAttachments} selectedSpinner={selectedSpinner} selectedEmailId={selectedEmailId} onAttachmentSelected={handleAttachmentSelected} onEmailIdSelected={handleEmailIdSelected} />
                            </div>
                            <div className='three'>
                                <div className="ckeditor-container">
                                    <CKEditor
                                        editor={Editor}
                                        data={emailBody ? emailBody : emailBodyTemplateData?.content || '<p>Compose <br>Email Here!</p>'}
                                        config={{
                                            height: '49%',
                                            image: {
                                               resizeUnit: 'px'
                                            },
                                        }}
                                        onReady={(editor) => {
                                            editorRef.current = editor;
                                        }}
                                    />
                                    {/* {contentError && <div className="error-message">{contentError}</div>} */}
                                </div>
                                <div className="button-container">
                                    <Tooltip title="Move to Email Preview" placement="top-start" arrow>
                                        <Button variant="contained" color="primary" type="submit" onClick={handleConfirmClick}>
                                            Next
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && (
                    <div className="loading-overlay">
                        <CircularProgress />
                    </div>
                )}
            </div>
        </>
    );
};

export default EmailCustomize;