import React from 'react'
 import Login from '../features/auth/components/Login'
const LoginPage = () => {
  return (

    <div  > 
    <Login/>
    </div>
  )
}

export default LoginPage