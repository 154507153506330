import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateUser, checkUser, signOut, refreshAccessToken , isAccessTokenExpired } from './authApi';
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';

  const initialState = {
  loggedInUser: null,
  loggedInUserRole:0,
  status: 'idle',
  error:null
};

 
export const createUserAsync = createAsyncThunk(
  'user/CreateUser',
  async (userData) => {
    const response = await CreateUser(userData);
     return response.data;
  }
);


export const checkUserAsync = createAsyncThunk(
  'user/checkUser',
  async (loginInfo, { getState, dispatch }) => {

    const response = await checkUser(loginInfo);
    const state = getState();
    const accessToken = Cookies.get('accessToken');
    const decodedAccessToken = jwt_decode(accessToken);

  
    if (isAccessTokenExpired(decodedAccessToken)) {
      const refreshToken = Cookies.get('refreshToken');
      const newAccessToken = await refreshAccessToken(refreshToken);
       console.log( newAccessToken)
       Cookies.set('accessToken', newAccessToken);

      decodedAccessToken = jwt_decode(newAccessToken);
    }    

    return response.data;
  }
);


 export const signOutAsync = createAsyncThunk(
  'user/signOut',
  async (userId) => {
    const response = await signOut(userId);
     return response.data;
  }
);

export const authSlice = createSlice({
  name: 'user',
  initialState,
   reducers: {
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
    }
  },
    extraReducers: (builder) => {
    builder
      .addCase(createUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUser = action.payload;
      })
      .addCase(checkUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUser = action.payload;
        state.loggedInUserRole=action.payload.admin;
      })
      .addCase(checkUserAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.error;
      })
      .addCase(signOutAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signOutAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUser = null;
       
      })
       ;
  },
});

export const selectLoggedInUser=(state)=>state.auth.loggedInUser
export const { setLoggedInUser } = authSlice.actions;

export const selectLoggedInUserRole=(state)=>state.auth.loggedInUserRole
export const selectError=(state)=>state.auth.error
export const selectAdminClaim = (state) => {
  const accessToken = Cookies.get('accessToken');
  if (accessToken) {
    const decodedAccessToken = jwt_decode(accessToken);
    return decodedAccessToken.admin;
  }
  return null;  
};

 

export default authSlice.reducer;
