import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { selectAllFromUsers, removeFromUserAsync, fetchAllFromUsersAsync } from '../adminSlice';
import './adminFromUsers.css';

const AdminFromUsers = () => {
  const usersData = useSelector(selectAllFromUsers);
  const dispatch = useDispatch();
  const users = usersData?.data?.userData ?? [];

  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('asc');

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleDelete = async (userId) => {
    await dispatch(removeFromUserAsync(userId));
    dispatch(fetchAllFromUsersAsync());
  };

  useEffect(() => {
    dispatch(fetchAllFromUsersAsync());
  }, [dispatch]);

  return (
    <div className='adminFromUserCss'>
      <TableContainer sx={{ marginTop: "4%", height: "36rem" }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ border: '1px solid #ddd', fontWeight:'bold' }}>
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={() => handleSort('id')}
                >
                  User ID
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd', fontWeight:'bold' }}>
                <TableSortLabel
                  active={orderBy === 'email'}
                  direction={orderBy === 'email' ? order : 'asc'}
                  onClick={() => handleSort('email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd', fontWeight:'bold' }}>
                <TableSortLabel
                  active={orderBy === 'clientId'}
                  direction={orderBy === 'clientId' ? order : 'asc'}
                  onClick={() => handleSort('clientId')}
                >
                  Client ID
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd', fontWeight:'bold' }}>
                <TableSortLabel
                  active={orderBy === 'tenantId'}
                  direction={orderBy === 'tenantId' ? order : 'asc'}
                  onClick={() => handleSort('tenantId')}
                >
                  Tenant ID
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd', fontWeight:'bold' }}>
                <TableSortLabel
                  active={orderBy === 'secret'}
                  direction={orderBy === 'secret' ? order : 'asc'}
                  onClick={() => handleSort('secret')}
                >
                  Secret
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd', fontWeight:'bold' }}>
                <TableSortLabel
                  active={orderBy === 'scopes'}
                  direction={orderBy === 'scopes' ? order : 'asc'}
                  onClick={() => handleSort('scopes')}
                >
                  Scopes
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd', fontWeight:'bold' }}>
                <TableSortLabel
                  active={orderBy === 'created'}
                  direction={orderBy === 'created' ? order : 'asc'}
                  onClick={() => handleSort('created')}
                >
                  Created Date
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ border: '1px solid #ddd', fontWeight:'bold' }}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell style={{ border: '1px solid #ddd' }}>{user.id}</TableCell>
                <TableCell style={{ border: '1px solid #ddd' }}>{user.email}</TableCell>
                <TableCell style={{ border: '1px solid #ddd' }}>{user.clientId}</TableCell>
                <TableCell style={{ border: '1px solid #ddd' }}>{user.tenantId}</TableCell>
                <TableCell style={{ border: '1px solid #ddd' }}>{user.secret}</TableCell>
                <TableCell style={{ border: '1px solid #ddd' }}>{user.scopes}</TableCell>
                <TableCell style={{ border: '1px solid #ddd' }}>{user.created}</TableCell>
                <TableCell style={{ border: '1px solid #ddd' }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDelete(user.id)}
                  >
                    <DeleteIcon style={{color:"red"}}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdminFromUsers;
