import React, { useEffect, useRef, useState } from 'react';
import Chip from '@mui/material/Chip';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import './emailChip.css';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { selectBcC, selectCC, selectSubjectLine, } from '../userSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { getFromEmailsAsync, selectFromEmailData } from '../userSlice';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { toast } from 'react-toastify';


const EmailChips = ({ selectedAttachmentsId, selectedEmailId, onAttachmentSelected, onEmailIdSelected, selectedSpinner }) => {

    const [selectedAttachments, setSelectedAttachments] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState('');
    const [selectedFile, setSelectedFile] = useState([])
    const [loadContent, setLoadContent] = useState(true)

    const ccData = useSelector(selectCC);
    const ccArray = ccData?.data?.fields[0]?.CC || [];

    const bcc = useSelector(selectBcC);
    const bccArray = bcc?.data?.fields[0]?.BCC || [];

    const subjectLine = useSelector(selectSubjectLine);
    const subjectLineArray = subjectLine?.data?.fields[0]?.subjectLine || [];

    const fromEmailData = useSelector(selectFromEmailData);
    const fields = fromEmailData?.data?.fields || [];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFromEmailsAsync());
    }, [dispatch]);

    const selectEmail = (event) => {
        const selectedEmail = event.target.value;
        const selectedEmailObject = fields.find(item => item.email === selectedEmail);

        if (selectedEmailObject) {
            const selectedId = selectedEmailObject.id;
            setSelectedEmail(selectedEmailObject.email);
            onEmailIdSelected(selectedId);
        }

    };

    const handleAttachmentChange = (event) => {
        const files = event.target.files;
    
        if (files.length > 0) {
          const maxSize = 20 * 1024 * 1024; // 20MB
          const selectedFile = files[0];
    
          if (selectedFile.size <= maxSize) {
            setSelectedFile(selectedFile);
    
            const newAttachments = Array.from(files);
            setSelectedAttachments(newAttachments);
            onAttachmentSelected(newAttachments);
          } else {
             toast.error('File size exceeds the limit (20MB)');
             event.target.value = null;
          }
        }
      };

    const handleRemoveFile = () => {
        setSelectedFile('');
        setSelectedAttachments([]);
        onAttachmentSelected([]);

        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.value = '';
        }
    };


    const prevSelectedSpinner = useRef(selectedSpinner);

    useEffect(() => {
        const showSpinnerForOneSecond = () => {
            setShowSpinner(true);
            setTimeout(() => {
                setShowSpinner(false);
            }, 1000);
        };

        // Check if selectedSpinner has changed
        const selectedSpinnerChanged = selectedSpinner !== prevSelectedSpinner.current;

        if (selectedSpinnerChanged) {
            console.log("Showing spinner");
            showSpinnerForOneSecond();
            setLoadContent(false);
        } else {
            console.log("Not showing spinner");
        }

        // Update the previous selectedSpinner after rendering
        prevSelectedSpinner.current = selectedSpinner;
    }, [selectedSpinner]);

    console.log(selectedSpinner, "selectedSpinner")


    useEffect(() => {
        if (selectedEmailId && fields.length) {
            const selectedField = fields.find(item => item.id === selectedEmailId)
            if (selectedField) {
                setSelectedEmail(selectedField.email)
            }
        }

    }, [selectedEmailId, fields])


    // const prevCcArray = useRef(ccArray);
    // const prevBccArray = useRef(bccArray);
    // const prevSubjectLineArray = useRef(subjectLineArray);

    // useEffect(() => {
    //     const showSpinnerForOneSecond = () => {
    //         setShowSpinner(true);
    //         setTimeout(() => {
    //             setShowSpinner(false);
    //         }, 1000);
    //     };

    //     // Convert subjectLineArray to an array if it's not already
    //     const subjectLineArrayAsArray = Array.isArray(subjectLineArray) ? subjectLineArray : [subjectLineArray];

    //     // Check if any element in any array has changed
    //     const ccArrayChanged = ccArray.some((element, index) => element !== prevCcArray.current[index]);
    //     const bccArrayChanged = bccArray.some((element, index) => element !== prevBccArray.current[index]);
    //     const subjectLineArrayChanged = subjectLineArrayAsArray.some((element, index) => element !== prevSubjectLineArray.current[index]);

    //     if (ccArrayChanged || bccArrayChanged || subjectLineArrayChanged) {
    //         console.log("Showing spinner");
    //         showSpinnerForOneSecond();
    //         setLoadContent(false);
    //     } else {
    //         console.log("Not showing spinner");
    //     }

    //     // Update the previous arrays after rendering
    //     prevCcArray.current = ccArray;
    //     prevBccArray.current = bccArray;
    //     prevSubjectLineArray.current = subjectLineArrayAsArray;
    // }, [ccArray, bccArray, subjectLineArray]);



    return (
        <>
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '50%', margin: '2%' }}>
                    <div className='file-style'>
                        <div className="labelStyles" style={{ fontSize: "small", fontWeight: "bold" }}>Attachment</div>
                        <div>
                            <div style={{ marginTop: "20px", display: "flex" }}>
                                <Tooltip title="Supported file formats: pdf, png, jpg, jpeg, xlsx, csv, doc, and docx.">
                                    <InputLabel
                                        htmlFor="fileInput"
                                        style={{
                                            justifyContent: "flex-start",
                                            padding: '10px',
                                            cursor: 'pointer',
                                            display: 'inline-block',
                                            fontWeight: "bold",
                                            fontSize: "small",
                                            border: '1px solid #ccc',
                                            transition: 'border-color 0.3s',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        <UploadFileIcon />
                                        Select File
                                    </InputLabel>
                                </Tooltip>
                                <Input
                                    id="fileInput"
                                    type="file"
                                    accept=".pdf, .doc, .docx"
                                    onChange={handleAttachmentChange}
                                    endAdornment={
                                        <InputAdornment>
                                        </InputAdornment>
                                    }
                                    style={{ display: 'none' }}
                                // inputProps={{ multiple: true }}
                                />
                                {(selectedAttachments.length > 0 || selectedAttachmentsId.length > 0) && (
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Tooltip title="Remove Attachment">
                                            <Button
                                                variant="outlined primary"
                                                color="secondary"
                                                onClick={handleRemoveFile}
                                                startIcon={<CloseIcon />}
                                                size="small"
                                                style={{ color: "black" }}
                                            >
                                                {/* <Typography variant="body2" style={{ fontSize: '14px' }}>
                Remove
            </Typography> */}

                                            </Button>
                                        </Tooltip>
                                    </div>
                                )}

                            </div>

                        </div>

                        <div style={{ marginTop: '10px', listStyleType: 'none' }}>
                            {selectedAttachmentsId.map((file, index) => (
                                <span key={index}>{file.name}</span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="chipContainerStyles">
                    <label style={{ fontSize: "small", fontWeight: "bold", width: "80px" }}>From Emails</label>
                    {/* <Select
                        style={{ fontWeight: "550", fontSize: "small", height: "30px", width: "200px", marginLeft: "28%", marginTop: "-7%" }}
                        value={fields.some(item => item.id === selectedEmailId) ? selectedEmailId : selectedEmail.id}
                        onChange={selectEmail}
                    >
                        {fields.map((fromEmailItem) => (
                            <MenuItem key={fromEmailItem.email} value={fromEmailItem.id} className='optionField'>
                                {fromEmailItem.email}
                            </MenuItem>
                        ))}
                    </Select> */}
                    <Select
                        style={{ fontWeight: "550", fontSize: "small", height: "30px", width: "200px", marginLeft: "28%", marginTop: "-7%" }}
                        value={selectedEmail}
                        defaultValue={selectedEmail}
                        onChange={selectEmail}
                    >
                        {fields.map((fromEmailItem) => (
                            <MenuItem key={fromEmailItem.id} value={fromEmailItem.email} className='optionField'>
                                {fromEmailItem.email}
                            </MenuItem>
                        ))}
                    </Select>

                    {showSpinner && (
                        <div className="loading-overlay">
                            <CircularProgress size={24} />
                        </div>
                    )}
                    <div className='component-content'>

                        {ccArray && ccArray.length > 0 ? (
                            <div className="scrollable-chip">
                                <label style={{ fontSize: "small", fontWeight: "bold" }}>CC</label>
                                <div className="scrollable-chip">
                                    {ccArray.map((ccEmail, index) => (
                                        <Chip
                                            key={index}
                                            label={ccEmail}
                                            title={ccEmail}
                                            color="primary"
                                            variant="outlined"
                                            style={{ margin: '4px', fontWeight: "550", fontSize: "small" }}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="scrollable-chip">
                                <label style={{ fontSize: "small", fontWeight: "bold" }}>CC</label>
                                <div className="scrollable-chip">
                                    <Chip
                                        label="No CC found"
                                        color="primary"
                                        variant="outlined"
                                        style={{ margin: '4px', fontWeight: "550", fontSize: "small" }}
                                    />
                                </div>
                            </div>
                        )}

                        {bccArray && bccArray.length > 0 ? (
                            <div>
                                <label style={{ fontSize: "small", fontWeight: "bold" }}>BCC</label>
                                <div className="scrollable-chip">
                                    {bccArray.map((bccEmail, index) => (
                                        <Chip
                                            key={index}
                                            label={bccEmail}
                                            title={bccEmail}
                                            color="secondary"
                                            variant="outlined"
                                            style={{ margin: '4px', fontWeight: "550", fontSize: "small" }}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <label style={{ fontSize: "small", fontWeight: "bold" }}>BCC</label>
                                <div className="scrollable-chip">
                                    <Chip
                                        label="No BCC found"
                                        color="secondary"
                                        variant="outlined"
                                        style={{ margin: '4px', fontWeight: "550", fontSize: "small" }}
                                    />
                                </div>
                            </div>
                        )}

                        {subjectLineArray && subjectLineArray.length > 0 ? (
                            <div className="scrollable-chip">
                                <label style={{ fontSize: "small", fontWeight: "bold" }}>Subject Line</label>
                                <div className="scrollable-chip">
                                    <Chip
                                        label={subjectLineArray}
                                        title={subjectLineArray}
                                        color="default"
                                        variant="outlined"
                                        style={{ margin: '4px', fontWeight: "550", fontSize: "small" }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="scrollable-chip">
                                <label style={{ fontSize: "small", fontWeight: "bold" }}>Subject Line</label>
                                <div className="scrollable-chip">
                                    <Chip
                                        label="No Subject Line found"
                                        color="default"
                                        variant="outlined"
                                        style={{ margin: '4px', fontWeight: "550", fontSize: "small" }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailChips;