import React from 'react'

const ErrorFallback = (props) => {
  return (
        <div>
            <h3 className='text-center'>Something went wrong.</h3>
            <p>{props.error.message}</p>
            <p>{props.error.stack}</p>
            <p>{props.info.componentStack}</p>
        </div>
  )
}

export default ErrorFallback