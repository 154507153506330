import React, { useState } from "react";
import { Button, TextField, Typography, Container, Paper, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff, Email } from "@mui/icons-material"; // Import icons
import { Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { selectLoggedInUser } from "../authSlice";
import { useSelector, useDispatch } from "react-redux";
import { checkUserAsync } from "../authSlice";
import './Login.css';
import avatar from '../../../assets/snadlerPartners.png';

const Login = () => {
  const user = useSelector(selectLoggedInUser);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <div className="login-container">
        {user && <Navigate to='/home' replace={true}></Navigate>}

        <div className="left-content">
          <img src={avatar} alt="Image" />
        </div>

        <div className="right-content">
          <Container maxWidth="xs">
            <Paper elevation={3} style={{ padding: 20 }}>
              <Typography variant="h5" gutterBottom>
                Email Blaster Login
              </Typography>

              <form onSubmit={handleSubmit((data) => {
                dispatch(checkUserAsync({ email: data.email, password: data.password }))
                console.log(data)
              })}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi,
                      message: "Email is not valid",
                    },
                  })}
                  error={!!errors.email}
                  InputProps={{ // InputAdornment to show the email icon
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
                <p className={`text-danger ${errors.email ? '' : 'd-none'}`}>
                  {errors.email && errors.email.message}
                </p>

                <TextField
                  id="password"
                  label="Password"
                  type={showPassword ? "text" : "password"} // Show/hide password
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...register("password", { required: "Password is required" })}
                  error={!!errors.password}
                  InputProps={{ // InputAdornment to show the password visibility toggle
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ padding: 0 }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <p className={`text-danger ${errors.password ? '' : 'd-none'}`}>
                  {errors.password && errors.password.message}
                </p>

                <Button variant="contained" fullWidth type="submit" sx={{ backgroundColor: "#1A4266" }}>
                  Login
                </Button>
              </form>
            </Paper>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Login;
