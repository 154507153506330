// A mock function to mimic making an async request for data
import axios from 'axios';
 import { urls } from '../../url';
import { checkAccessToken } from '../auth/authApi';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const TOASTER_TIMEOUT = 1000;

const handleApiError = (error) => {
  const  response  = error;
  if (response) {
    if (response.status === 400) {
      handleUnauthorizedError();
    } else if(response.status === 400){
      handleBadRequestError(response);
    } 
    else{
      console.log( response)
    }
  }
};


const handleBadRequestError = async (response) => {
  try {
    const responseError = await response.data?.message || 'An error occurred';
    toast.error(responseError, {
      position: 'top-right',
      autoClose: TOASTER_TIMEOUT,
    });
  } catch (error) {
    toast.error('An error occurred', {
      position: 'top-right',
      autoClose: TOASTER_TIMEOUT,
    });
  }
};


const handleUnauthorizedError = () => {
  toast.error('Session expired, Please Login Again!', {
    position: 'top-right',
    autoClose: TOASTER_TIMEOUT,
  });
  
  setTimeout(() => {
    window.location.reload();
    Cookies.remove('accessToken');
  }, 3000);
};


export const uploadUsers = async (file) => {
   const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  const formData = new FormData();
  formData.append('file', file, 'user-uploaded-excel-file.xlsx');

  try {
    const response = await axios.post(urls.uploadExcel, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
      return response.data;
   
  } catch (error) {
     if (error.response && error.response.status === 400) {
      console.log( error.response)
       toast.error( error.response.data.data.message, {
        position: 'top-right',
        autoClose: 3000
      });
    }
    else{
      handleApiError()
    }
 
   }
};

export const fetchUserEmail = async () => {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  try {
    const response = await axios.get(urls.fetchEmail,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {

    handleApiError(error);
    }
};

export const emailBodyTemplate = async () => {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  try {
    const response = await axios.get(urls.bodyTemplate,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
    }
};


export async function getHeaders(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "rowId": rowId } };
    const response = await fetch(urls.getHeaders, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    return error;
   }
}

export async function getFromEmails(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const response = await fetch(urls.getFromEmails, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function uploadFromEmails(selectedEmailId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "id": selectedEmailId } };
    const response = await fetch(urls.uploadFromEmails, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function getCC(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "rowId": rowId } };
    const response = await fetch(urls.getCC, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function getBcc(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "rowId": rowId } };
    const response = await fetch(urls.getBcc, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function getSubjectLine(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "rowId": rowId } };
    const response = await fetch(urls.getSubjectLine, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export const uploadAttachment = async (formData) => {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  try {
    const response = await axios.post(urls.uploadFile, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleApiError(error);
    }
};


export async function uploadEmailData(emailData) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "content": emailData } };
    const response = await fetch(urls.uploadEmailBody, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',  
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    toast.success(data.data.message, {
      position: 'top-right',
      autoClose: TOASTER_TIMEOUT
    });
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function getPreviewEmail(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "rowId": rowId } };
    const response = await fetch(urls.getPreviewEmail, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function EmailBlast() {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const response = await fetch(urls.confirmEMailBlast, {
      method: "POST",
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    let data; // Define the data variable outside the if-else block

    if (response.status === 400) {
      data = await response.json();
      toast.error(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
    } else {
      data = await response.json();
      toast.success(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }

    return { data };  
  } catch (error) {
    handleApiError(error);
    }
}

export async function getEmailBlastStatus() {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }

    const response = await fetch(urls.emailBlastStatus, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}