import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../../header/Navbar'

const UserLayout = () => {
  return (
    <div>
    <Navbar/>  
    </div>
  )
}

export default UserLayout