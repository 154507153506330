import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './navbar.css'
import avatar from '../../../src/assets/sandler.jpg'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useDispatch, useSelector } from 'react-redux'
import { selectAdminClaim, signOutAsync } from '../auth/authSlice';
import { useSpring, animated, config } from 'react-spring';
import { Link as RouterLink } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircleIcon from '@mui/icons-material/Circle';

import { fetchEmailBlastStatusAsync, emailBlastStatusData } from '../user/userSlice.js'

const pages = [];
const settings = [{ name: 'Logout', link: '/logout' },];


const AnimatedSettingsMenu = ({ anchorElUser, onClose, userEmail, settings }) => {

  const dispatch = useDispatch();
  const menuAnimation = useSpring({
    opacity: Boolean(anchorElUser) ? 1 : 0,
    transform: Boolean(anchorElUser) ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20px, 0)',
    vertical: 'top',
    horizontal: 'right',
    height: Boolean(anchorElUser) ? 200 : 0,
    config: {
      tension: 120, // Increase tension for stiffness
      friction: 14, // Increase friction for slower motion
    },
  });
  const handleLogout = () => {
    dispatch(signOutAsync());
  }

  return (
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      TransitionComponent={(props) => (
        <animated.div style={menuAnimation} {...props} />
      )}
      open={Boolean(anchorElUser)}
      onClose={onClose}
    >
      <MenuItem style={{ color: "grey", cursor: 'default' }}>
        {userEmail.email}
      </MenuItem>
      {settings.map((item) => (
        <MenuItem style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleLogout}>
          {item.name}
        </MenuItem>
        // <a href={item.link} ></a>
      ))}

    </Menu>
  );
};

const EmailBlastStatusComponent = ({ emailsQueued, estimatedTime, statusLastUpdated }) => {
  const dispatch = useDispatch();
  
  const handleEmailBlastStatusRefresh = (() => {
    dispatch(fetchEmailBlastStatusAsync());
  })

  return (
    <Box sx={{ backgroundColor: '#1A4266', color: 'white', height: '55px', borderRadius: '5px', marginRight: 2, borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
      <Box sx={{ display: 'flex', flexFlow: "row wrap", fontSize: '15px' }}>
        {/* In Progress */}
        <Box sx={{ textAlign: 'right', marginRight: 2, marginLeft: 1 }}>
          <span>
            <CircleIcon color='primary' sx={{ height: '8px' }} />
            <b>Emails in Queue:</b> {emailsQueued}
          </span>
        </Box>

        {/* ETA */}
        <Box sx={{ textAlign: 'left', marginRight: 2 }}>
          <span>
            <CircleIcon color='secondary' sx={{ height: '8px' }} />
            <b>Estimated Time: </b> {estimatedTime}
          </span>
        </Box>
      </Box>
      <hr style={{ marginTop: 5, marginBottom: 3, color: 'grey' }}></hr>
      {/* Last Updated */}
      <Box sx={{ display: 'flex', flexFlow: "row wrap", justifyContent: 'flex-end' }}>
        <Box sx={{ fontSize: '10px', textAlign: 'right', marginRight: 2, marginLeft: 1, fontStyle: 'italic', fontWeight: 'medium' }}>
          <span><b>Last Updated:</b> {statusLastUpdated}</span>
          <Tooltip title="Click to Refresh">
            <RefreshIcon sx={{ fontSize: 'medium', marginLeft: 1, cursor: 'pointer' }} onClick={handleEmailBlastStatusRefresh}></RefreshIcon>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )

}

const Navbar = () => {

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmailBlastStatusAsync());
  }, []);


  const emailBlastStatus = useSelector(emailBlastStatusData);
  const emailsQueued = emailBlastStatus?.data?.triggered ?? 0;
  const estimatedTime = emailBlastStatus?.data?.estimatedTime ?? 0;
  const statusLastUpdated = emailBlastStatus?.metaData?.requestTime ?? "";

  const role = useSelector(selectAdminClaim)

  const accessToken = Cookies.get('accessToken');

  const userEmail = jwt_decode(accessToken);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate()

  const handleRefreshClick = () => {
    navigate('/user')
  };
  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: '#1A4266' }} id="navbar">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img src={avatar} style={{ height: "40px", width: "40px", marginRight: '2%' }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Email Blaster
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <EmailBlastStatusComponent emailsQueued={emailsQueued} estimatedTime={estimatedTime} statusLastUpdated={statusLastUpdated}/>
            <Box sx={{ flexGrow: 0 }}>
              {role === 0 &&
                <Tooltip title="Go To Excel Upload">
                  <IconButton color="inherit" onClick={handleRefreshClick} style={{ marginRight: '10px' }}>
                    <HomeOutlinedIcon />
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title="Open Settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <AnimatedSettingsMenu anchorElUser={anchorElUser} onClose={handleCloseUserMenu} userEmail={userEmail} settings={settings} />

              {/* <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div className="block px-3 py-2 text-base font-medium text-gray-600" style={{ color: 'grey' }}>
                  {userEmail.email}
                </div>
                {settings.map((item) => (
                  <div key={item.name} className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                    <a href={item.link} style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}>{item.name}</a>
                  </div>
                ))}
              </Menu> */}

            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </>
  )
}

export default Navbar