import React from 'react'
import { selectAdminClaim } from '../features/auth/authSlice'
import { useSelector } from 'react-redux'
 import { Navigate } from 'react-router-dom'

const Home = () => {

   const role = useSelector(selectAdminClaim)
    console.log(role)
  
  if(role === 0){
   return  <Navigate to='/user'></Navigate>
  }else{
   return <Navigate to='/admin'></Navigate>
  }
     
}

export default Home