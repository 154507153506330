import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createUserAsync } from '../adminSlice';
import './admin.css'

const AdminRegister = () => {
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState("user"); // Default role

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <div>
      <Container maxWidth="xs" sx={{ marginTop: "4%", height: "36rem" }}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6" gutterBottom>
            Register Users
          </Typography>

          <form
            onSubmit={handleSubmit((data) => {
               dispatch(createUserAsync({ email: data.email, password: data.password, userRole: selectedRole }));
              console.log(data);
            })}
          >
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi,
                  message: "Email is not valid",
                },
              })}
              error={!!errors.email}
            />
            <p className={`text-danger ${errors.email ? '' : 'd-none'}`}>
              {errors.email && errors.email.message}
            </p>

            <TextField
              id="password"
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              {...register("password", { required: "Password is required" })}
               error={!!errors.password}
            />
            <p className={`text-danger ${errors.password ? '' : 'd-none'}`}>
              {errors.password && errors.password.message}
            </p>

            <FormControl component="fieldset">
              <FormLabel component="legend">Select Role</FormLabel>
              <RadioGroup
                aria-label="role"
                name="userRole"
                value={selectedRole}
                onChange={handleRoleChange}
              >
                <FormControlLabel
                  value="user"
                  control={<Radio />}
                  label="User"
                  style={{ fontSize: '5px' }}
                />
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Admin"
                  style={{ fontSize: '5px' }}
                />
               </RadioGroup>
            </FormControl>

            <Button variant="contained" color="primary" fullWidth type="submit">
              Register
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default AdminRegister;
