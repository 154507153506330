import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateUser, fetchAllUsers , removeUser, CreateFromUser, fetchAllFromUsers, removeFromUser} from './adminApi';

const initialState = {
  users: [],
  fromUsers: [],
  status: 'idle',
  selectedUser: null
};
 
export const createUserAsync = createAsyncThunk(
  'admin/CreateUser',
  async (userData) => {
    const response = await CreateUser(userData);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const createFromUserAsync = createAsyncThunk(
  'admin/CreateFromUser',
  async (fromUserData) => {
    const response = await CreateFromUser(fromUserData);
    return response.data;
  }
)


export const fetchAllUsersAsync = createAsyncThunk(
  'admin/fetchAllUsers',
  async ( ) => {
    const response = await fetchAllUsers();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchAllFromUsersAsync = createAsyncThunk(
  'admin/fetchAllFromUsers',
  async ( ) => {
    const response = await fetchAllFromUsers();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const removeUserAsync = createAsyncThunk(
  'admin/removeUser',
  async (id) => {
    const response = await removeUser(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const removeFromUserAsync = createAsyncThunk(
  'admin/removeFromUser',
  async (id) => {
    const response = await removeFromUser(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);


export const adminSlice = createSlice({
  name: 'admin',
  initialState,
   reducers: {
    
  },
 
  extraReducers: (builder) => {
    builder
    .addCase(createUserAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createUserAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.value = action.payload
    })
    .addCase(fetchAllUsersAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchAllUsersAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.users = action.payload;              
    })
    .addCase(removeUserAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(removeUserAsync.fulfilled, (state, action) => {       
      state.users = action.payload;
    })
    .addCase(createFromUserAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createFromUserAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.value = action.payload;
    })
    .addCase(fetchAllFromUsersAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchAllFromUsersAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.fromUsers = action.payload;
    })
    .addCase(removeFromUserAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(removeFromUserAsync.fulfilled, (state, action) => {
      state.fromUsers = action.payload;
    })
  },
});

export const selectAllUsers = (state) => state.admin.users;
export const fetchAllusersData = (state)=>state.admin.selectedUser
export const selectAllFromUsers = (state) => state.admin.fromUsers;


 
export default adminSlice.reducer;
