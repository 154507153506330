import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice'
import adminReducer from '../features/admin/adminSlice'
import userReducer from '../features/user/userSlice'
export const store = configureStore({
  reducer: {
    auth:authReducer,
    admin:adminReducer,
    user:userReducer,
  },
});
