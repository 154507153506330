import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import InfoIcon from '@mui/icons-material/Info';

const InfoButton = ({ onClick, toolTipContent }) => {
  return (
    <Tooltip
      title={toolTipContent}
      arrow
      placement="left-start"
      sx={{ m: 1 }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'common.white',
            color: 'common.black',
            border: '1px solid grey',
            '& .MuiTooltip-arrow': {
              color: 'grey',
            },
          },
        },
      }}>
      <Fab size="small" onClick={onClick} style={{ position: 'fixed', right: '15px', top: '80px',   border: '1px solid black', color: "white"}}>
        <InfoIcon style={{color: '#1A4266'}} />
      </Fab>
    </Tooltip>
  );
};


export default InfoButton;
