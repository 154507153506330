import React from 'react';
import ErrorFallback from './ErrorFallback'

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' }
  };

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;
    return hasError ? <ErrorFallback error={error} info={info} /> : children;
  }
}

export default ErrorBoundary;